import React, {useContext, useEffect, useState} from 'react'
// @ts-ignore
import imagesLoaded from 'imagesloaded'
// @ts-ignore
import {Link} from 'gatsby'

// @ts-ignore
import content from '../../content/artworks.yaml'
// import Layout from '@components/Layout'
import Layout from '../components/Layout'
import { IArtworkItem } from '../types/index'
import { t } from '../components/Translator'
import { artworks, unknown_creator } from '../constants'
import { AppContext } from '../components/ContextProvider'

const Item: React.FC<IArtworkItem> = ({ artwork }) => {

    const context = useContext(AppContext)

    // (!) REMARK: React Hooks
    //     t(...) calls Hook useContext() in Translator module, cannot be called @runtime in non-body function impl.
    //     "Error: Invalid hook call. Hooks can only be called inside of the body of a function component."
    // @ts-ignore
    const creator = artwork.creator === 'unknown' ? t(unknown_creator) : t(artwork.creator)

    const link = '/artworks/' + artwork.link

    // (!) setNewCurrentNavigationLink() also caches current locale in new context
    //     only works with Gatsby Link but fails with a href oncClick
    const onClick = () => {
        context.setNewCurrentNavigationLink(link)
    }

    return (
        <div className="grid-item">
            <div className="format-standard">
                <Link className="media-box grid-featured-img"
                      to={link} onClick={onClick}>
                    {/*<img src="http://placehold.it/600x400&amp;text=IMAGE+PLACEHOLDER" alt="artwork_01"/>*/}
                    <img src={artwork.thumbnail} alt=""/>
                </Link>
                <div className="grid-item-content">
                    <h3 className="short">
                        <Link to={link} onClick={onClick}>{t(artwork.title)}</Link>
                    </h3>
                    {/* (!) artist link extension to consider */}
                    {/*<div class="meta-data artists-list">Door <a href="#">onbekend</a></div>*/}
                    <div className="metadata-thumbnail" dangerouslySetInnerHTML={{ __html: creator }}/>
                    <div className="metadata-thumbnail">{t(artwork.period)}</div>
                    {/* (!) meta-data links/tags extension to consider */}
                    {/*<div class="meta-data"><a href="#">Beeldhouwwerk</a>, <a href="#" rel="tag">Brons</a>, <a href="#" rel="tag">Email</a></div>*/}
                    <div className="meta-data">{t(artwork.material)}</div>
                    <div className="spacer-20"></div>
                    <Link className="pull-right basic-link read-more-right-caret"
                          to={link} onClick={onClick}>
                        {t(content.read_more)}
                    </Link>
                </div>
            </div>
        </div>
    )
}

const ArtworksPage: React.FC = () => {

    const items: Array<{ link: string, thumbnail: string, creator: object, title: object, period: object, material: object }> = []

    // (!) REMARK: avoid the React file-system/fs rabbit hole -> read directory failed attempts:
    //      import * as artworks from '../../content/artworks'
    //      import fs from 'fs'; import { recurse } from 'file-system'; import fs from 'fs-extra'
    //      const fs = require('file-system') var fs = require('fs')
    //      fs.readdirSync('../../content/artworks')
    artworks.map((artwork: { filename: string, path: string }, index: number) => {
        // console.log('# filename = ' + filename)
        // (!) prepared concatenated string var as argument FAILS e.g. require(artworkFilePath)
        const artObject = require('../../content/artworks/' + artwork.filename)
        const item = {
            link: artwork.path,
            thumbnail: artObject.thumbnail,
            creator: artObject.creator,
            title: artObject.title,
            period: artObject.period,
            material: artObject.material
        }
        items.push(item)
    })

    /**
      Isotope config from https://isotope.metafizzy.co/ the vanilla JS flavour way (NO JQuery)
      installed: npm install isotope-layout
     */
    useEffect(() => {

        // Isotope - imagesLoaded callback, vanilla JS - https://codepen.io/desandro/pen/QbNBpB
        // (!) wait until images are loaded before Isotope init - https://isotope.metafizzy.co/layout.html
        const Isotope = require('isotope-layout')
        const grid = document.querySelector('.grid')
        imagesLoaded( grid, function() {
            // init Isotope after all images have loaded
            const iso = new Isotope( grid, {
                itemSelector: '.grid-item',
                masonry: {
                    columnWidth: '.grid-sizer',
                }
            })
            document.querySelectorAll('.grid-item').forEach(element => {
                element.classList.add('fade-in')
                // element.style.opacity = 1
            })
        })

        // (!) googled Isotope + React + useEffect() : no examples of useEffect return found
        return () => {
            // somethings like : carousel.dispose()  ?
        }
    }, [])

    return (
        <Layout head={content.head} banner={content.banner}>
            <div className="grid">
                <div className="grid-sizer"></div>
                {items.map((artwork, index: number) =>
                    <Item key={index} artwork={artwork}/>
                )}
            </div>
            {/* todo: activate pagination
            <ul className="pagination">
                <li>
                    <a href="#" title="First"><i className="fa fa-chevron-left"></i></a>
                </li>
                <li className="active"><span>1</span></li>
                <li><a href="#" className="">2</a></li>
                <li><a href="#" className="">3</a></li>
                <li><a href="#" title="Last">
                    <i className="fa fa-chevron-right"></i></a>
                </li>
            </ul>
            */}
        </Layout>
    )
}

export default ArtworksPage